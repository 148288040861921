import * as React from 'react';
import { withUnpublishedPreview } from 'gatsby-source-prismic';

import Contract from '../pages/contact';
import Journal from '../templates/journal';
import Page from '../templates/page';
import ContractProject from '../templates/contract-project';
import PreviewPage from '../pages/preview';

const NotFoundPage = () => (
  <>
    <h1>Page not found!</h1>
  </>
);

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(PreviewPage, {
  templateMap: {
    contract: Contract,
    journal: Journal,
    page: Page,
    project: ContractProject,
  },
});
